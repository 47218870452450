
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
import Api from '@/api/Api'
import { ApiPublicProfile } from '@/types'

export default defineComponent({
  name: 'Leaderboards',
  components: {
    NavBar
  },
  data () {
    return {
      users: new Map<number, ApiPublicProfile[]>(),
      sort: this.$route.query.sort as string,
      page: 0,
      end: false,
      loadingpage: 0
    }
  },
  mounted () {
    if (this.sort === 'points') {
      this.loadByPoints()
    } else {
      this.loadByWins()
    }

    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    usersAsArray () {
      let res: ApiPublicProfile[] = []
      let i = 0
      while (true) {
        const batch = this.users.get(i)
        if (batch) {
          res = res.concat(batch)
        } else {
          return res
        }
        i++
      }
    }
  },
  methods: {
    loadByWins (page?: number) {
      if (this.end) {
        return
      }
      if (page) {
        if (this.loadingpage < page) {
          this.loadingpage = page
          Api.getTopWins(100, page).then(data => {
            if (data.length === 0) {
              this.end = true
            } else {
              this.users.set(page, data)
              this.page = page
            }
          })
        }
      } else {
        Api.getTopWins(100, 0).then(data => {
          this.users.clear()
          this.users.set(0, data)
          this.page = 0
          this.end = false
        })
      }
    },
    loadByPoints (page?: number) {
      if (this.end) {
        return
      }
      if (page) {
        Api.getTopPoints(100, page).then(data => {
          if (data.length === 0) {
            this.end = true
          } else {
            this.users.set(page, data)
            this.page = page
          }
        })
      } else {
        Api.getTopPoints(100, 0).then(data => {
          this.users.clear()
          this.users.set(0, data)
          this.page = 0
          this.end = false
        })
      }
    },
    onScroll () {
      console.log(window.scrollY, window.innerHeight, document.body.scrollHeight)
      if (window.scrollY + window.innerHeight > document.body.scrollHeight - 300) {
        if (this.sort === 'points') {
          this.loadByPoints(this.page + 1)
        } else {
          this.loadByWins(this.page + 1)
        }
      }
    }
  }
})
